import { IsBoolean, IsNumber, IsObject, IsOptional } from 'class-validator';

export class PaginatedSearchParams<T = Record<string, any>> {
    @IsNumber()
    @IsOptional()
    offset?: number;

    @IsNumber()
    @IsOptional()
    limit?: number;

    @IsBoolean()
    @IsOptional()
    deleted?: boolean;

    @IsObject()
    @IsOptional()
    filter?: T;
}
